import { HttpClient, HttpEvent, HttpEventType, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom, lastValueFrom, map, Observable, tap } from 'rxjs';
import {
	ICreateReleaseRequest,
	IReleaseDetailReadModel,
	IReleaseHeaderReadModel,
	IUpdateReleaseRequest,
} from './models/release.types';
import { ReleaseReadModel } from '../stores/models/release-store.types';
import { ReleaseModel } from '../../models/release-model';
import {
	ReleaseTrackMusicArtistModel,
	ReleaseTrackSongModel,
	ReleaseTrackExerciseModel,
	ReleaseTrackModel,
	ReleaseTrackTagModel,
} from '../../models/release-track.model';

@Injectable()
export class ReleaseService {
	constructor(private httpClient: HttpClient) {}

	getReleases(): Observable<IReleaseHeaderReadModel[]> {
		return this.httpClient.get<IReleaseHeaderReadModel[]>('/api/releases');
	}

	async getAllReleases(): Promise<ReleaseModel[]> {
		return await lastValueFrom(
			this.httpClient.get<ReleaseReadModel[]>('/api/releases', { observe: 'body', responseType: 'json' }).pipe(
				map((response: ReleaseReadModel[]) => {
					return response.map((item: ReleaseReadModel) => {
						const releaseTracks = item.releaseTracks.map((rt) => {
							const songs = rt.songs.map((s) => {
								const artists = s.musicArtists.map((a) => {
									return new ReleaseTrackMusicArtistModel(a.musicArtistId, a.artistName, a.isFeaturedArtist, a.ordinal);
								});

								return new ReleaseTrackSongModel(
									s.releaseTrackId,
									s.releaseTrackSongId,
									s.songId,
									s.songName,
									s.trackLengthInSeconds,
									s.ordinal,
									artists,
								);
							});

							const exercises = rt.exercises.map((e) => {
								return new ReleaseTrackExerciseModel(e.releaseTrackId, e.exerciseId, e.exerciseName);
							});

							const tags = rt.tags.map((t) => {
								return new ReleaseTrackTagModel(t.releaseTrackId, t.tagId, t.tagName);
							});

							return new ReleaseTrackModel(
								rt.releaseTrackId,
								rt.programId,
								rt.programName,
								rt.programFormatId,
								rt.programFormatName,
								rt.programFormatTrackId,
								rt.releaseId,
								rt.releaseName,
								item.releaseNumber,
								rt.releaseAlbumArtUrl,
								rt.releaseAlbumArtThumbnailUrl,
								rt.trackNumber,
								rt.trackDesignation,
								rt.releaseTrackSubtypeId,
								rt.subtype,
								rt.trackFocus,
								rt.isBonusTrack,
								rt.isAlternateTrack,
								rt.averageRating,
								rt.numberOfRatings,
								songs,
								exercises,
								tags,
							);
						});

						return new ReleaseModel(
							item.releaseId,
							item.releaseNumber,
							item.releaseName,
							item.releaseYear,
							item.releaseMonth,
							item.releaseAlbumArtUrl,
							item.releaseAlbumArtThumbnailUrl,
							item.programId,
							item.programName,
							item.programFormatId,
							item.programFormatName,
							releaseTracks,
						);
					});
				}),
			),
		);
	}

	async getRelease(id: string): Promise<IReleaseDetailReadModel> {
		return await lastValueFrom(this.httpClient.get<IReleaseDetailReadModel>(`/api/releases/${id}`));
	}

	async createRelease(request: ICreateReleaseRequest): Promise<IReleaseHeaderReadModel> {
		return await firstValueFrom(this.httpClient.post<IReleaseHeaderReadModel>('/api/releases', request));
	}

	async updateRelease(request: IUpdateReleaseRequest): Promise<IReleaseHeaderReadModel> {
		return await firstValueFrom(this.httpClient.put<IReleaseHeaderReadModel>('/api/releases', request));
	}

	public importReleaseFromMusicTrack(file: File, progressCallback: (progress: number) => void): Promise<unknown> {
		const formData = new FormData();
		formData.append('file', file);

		const req = new HttpRequest('POST', '/api/releases/import', formData, {
			reportProgress: true,
			responseType: 'json',
		});

		return lastValueFrom(
			this.httpClient.request(req).pipe(
				tap((event: HttpEvent<any>) => {
					switch (event.type) {
						case HttpEventType.UploadProgress:
							if (event.total) {
								const progress = Math.round((100 * event.loaded) / event.total);
								progressCallback(progress);
							}
							break;
						case HttpEventType.Response:
							return event.body;
					}
				}),
			),
		) as Promise<unknown>;
	}
}
